export enum ServiceProvider {
  Demo = 'Demo',
  Xactus = 'Xactus',
  CIC = 'CIC',
  LendersOne = 'LendersOne',
  Halcyon = 'Halcyon',
  OptimalBlue = 'OptimalBlue',
  AccountChek = 'AccountChek',
  FMInhouse = 'FMInhouse',
}

export enum CredentialsType {
  Credit = 'Credit',
  TaxTranscripts = 'TaxTranscripts',
  VerificationOfEmployment = 'VerificationOfEmployment',
  Pricing = 'Pricing',
  VerificationOfAssets = 'VerificationOfAssets',
}

export interface LoanOfficerCredentials {
  serviceProvider?: ServiceProvider;
  credentialsType: CredentialsType;
  userId: string;
  isDefault: boolean;
}
